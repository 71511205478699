<template>
    <div class="border border-gray-400 rounded-3xl px-6 pt-4 pb-6">
        <div class="flex items-center">
            <div class="text-primary font-bold text-xl">Destinations</div>
            <div class="flex items-center bg-gray-100 rounded-lg w-64 px-2 py-1 ml-8">
                <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
                <input
                    v-model="search"
                    class="bg-transparent focus:outline-none w-full ml-2"
                    placeholder="Search destination"
                />
            </div>
        </div>
        <div class="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6 mt-8" v-if="filteredDestinations.length > 0">
            <div
                v-for="destination in filteredDestinations"
                :key="destination.id"
                @click="openDetailPage(destination)"
                class="rounded-xl overflow-hidden cursor-pointer border border-gray-200 shadow-lg hover:shadow-hover"
            >
                <div class="font-bold bg-yellow py-1 px-3">{{ destination.judul }}</div>
                <div class="px-3 pt-3">
                    <img
                        :src="destination.link_foto"
                        class="object-cover w-full h-28 rounded-xl border border-gray-300"
                    />
                </div>
                <div class="font-bold text-center text-sm py-2">{{ destination.nama_wilayah }}</div>
            </div>
        </div>
        <div class="grid mt-8" v-else>
            <div class="text-xl font-bold text-center">Destinations not found...</div>
        </div>
    </div>
</template>

<script>
    import { Slugify } from '@/utils';

    export default {
        name: 'DestinationIndex',

        data() {
            return {
                search: '',
                destinations: []
            };
        },

        computed: {
            filteredDestinations() {
                return this.destinations.filter(val => val.judul.toLowerCase().includes(this.search.toLowerCase()));
            }
        },

        async created() {
            this.$store.commit('setOverlayLoading', true);

            this.$store.commit('setBreadcrumbItems', [
                { text: 'Home', routeName: 'Home' },
                { text: 'Destination', routeName: 'DestinationIndex' }
            ]);

            try {
                const response = await this.$http.get(`${this.$apiTripweWeb}/destination/list`, {params: {
                    limit: 100
                }});
                
                if(response.data.status != 200){
                    throw response.data.message;
                }
                this.destinations = response.data.data.item;
            } catch (error) {
                this.$toasted.global.error(error);
            } finally {
                this.$store.commit('setOverlayLoading', false);
            }

        },

        methods: {
            openDetailPage(destination) {
                this.$router.push({
                    name: 'DestinationDetail',
                    params: { slug: Slugify(destination.judul), destination }
                });
            }
        }
    };
</script>
